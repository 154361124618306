
import { NavLink } from "react-router-dom";

import { ReactComponent as Path} from "../../assets/icons/Path.svg";
import { ReactComponent as Download} from "../../assets/icons/download.svg";
import { ReactComponent as Settings} from "../../assets/icons/settings.svg";
import { ReactComponent as Help} from "../../assets/icons/help.svg";
import { ReactComponent as Profile} from "../../assets/icons/profile.svg";
import { ReactComponent as Calendar} from "../../assets/icons/calendar.svg";

import './questionsSideNav.scss'

const navData = [
    {
        id: 0,
        icon: <Settings />,
        text: "FàQ Générale ",
        link: "FàQ Générale "
    },
    {
        id: 1,
        icon: <Help />,
        text: "Utilisation",
        link: "Utilisation"
    },
    {
        id: 2,
        icon: <Profile />,
        text: "Partenaires",
        link: "Partenaires"
    },
    {
        id: 3,
        icon: <Calendar />,
        text: "Durabilité",
        link: ""
    },
]

const QuestionsSideNav = ({ className }) => {
    return (
        <div className={className}>
            {navData.map(item => {
                return <NavLink 
                key={item.id} 
                className="navlink" 
                to={item.link} 
                style={({ isActive }) => {
                    return {
                        color: isActive ? "#6584CC" : "#2B2B2B",
                        stroke: isActive ? "#6584CC" : "#2B2B2B",
                    };
                }}>
                <div className="navlink_icon">{item.icon}</div>
                <span className="navlink_text" >{item.text}</span>
            </NavLink>
            })}
        </div>
        
    )
}

export default QuestionsSideNav
