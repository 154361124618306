import React, { useState } from 'react';
import AppText from '../Text/AppText';
import { ReactComponent as Path } from "../../assets/icons/Path.svg";
import { ReactComponent as PathPlus } from "../../assets/icons/pathPlus.svg";
import './question.scss';

const data = [
  { id: 0, content: 'It is a long established fact that a reader will be ?', answer: 'Answer 1' },
  { id: 1, content: 'It is a long established fact that a reader will be ?', answer: 'Answer 2' },
  { id: 2, content: 'It is a long established fact that a reader will be ?', answer: 'Answer 3' },
  { id: 3, content: 'It is a long established fact that a reader will be ?', answer: 'Answer 4' },
  { id: 4, content: 'It is a long established fact that a reader will be ?', answer: 'Answer 5' }
];

const Question = ({ className, questionType, onClick }) => {
  const [expandedQuestion, setExpandedQuestion] = useState(null);

  const toggleQuestion = (questionId) => {
    if (expandedQuestion === questionId) {
      setExpandedQuestion(null);
    } else {
      setExpandedQuestion(questionId);
    }
  };

  return (
    <>
      {data.map((question) => (
        <div key={question.id} className='question-container'>
          <div
            className='question-header'
            onClick={() => toggleQuestion(question.id)}
          >
            <AppText
              text={question.content}
              fontSize='md'
              color='black'
              className='b-700'
            />
            {expandedQuestion === question.id ?  <Path /> : <PathPlus />}
          </div>
          {expandedQuestion === question.id && (
            <div className='question-answer'>
              <AppText
                text={question.answer}
                fontSize='md'
                color='black'
              />
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default Question;
