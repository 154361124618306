import { NavLink } from "react-router-dom";
import NavBar from "../../Components/NavBar/NavBar";
import Footer from "../../Components/Footer/Footer";
import AppTitle from "../../Components/Title/AppTitle";
import AppText from "../../Components/Text/AppText";
import AppButton from "../../Components/Button/AppButton";
import AppIcon from "../../Components/Icon/AppIcon";
import logoCCIF from '../../assets/icons/FO_LogoBlanc.png'
import locationMap from '../../assets/img/map-location.png'
import filters from '../../assets/img/ccif_card.png'
import settingIcon from '../../assets/icons/setting.svg'
import enterprise from '../../assets/img/template_app_2@x2.png'
import favoris from '../../assets/img/template_app_3@x2.png'
import iconEconomy from '../../assets/icons/icon_economy.svg'
import iconPackage from '../../assets/icons/icon_package.svg'
import RegisteredCompany from '../../Components/RegisteredCompany/RegisteredCompany';
import PracticalInfo from '../../Components/PracticalInfo/PracticalInfo';
import { useEffect, useContext, useState } from "react";
import { gsap } from "gsap";
import { useTranslation } from 'react-i18next';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import './home.scss';

function Home({}) {
  const { t } = useTranslation();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.utils.toArray(".image-anim").forEach((section) => {
      gsap.fromTo(
        section,
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          scrollTrigger: {
            trigger: section,
            start: "top 60%",
            end: "bottom 20%",
            toggleActions: "play",
          },
        }
      );
    });
    
    gsap.utils.toArray(".text-anim").forEach((section) => {
      gsap.fromTo(
        section,
        { opacity: 0, y: 150 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          scrollTrigger: {
            trigger: section,
            start: "top 60%",
            end: "bottom 20%",
            toggleActions: "play",
          },
        }
      );
    });

    gsap.utils.toArray(".icon-anim").forEach((section) => {
      gsap.fromTo(
        section,
        { opacity: 0, scale: 0 },
        {
          opacity: 1,
          scale: 1,
          duration: 1,
          scrollTrigger: {
            trigger: section,
            start: "top 60%",
            end: "bottom 20%",
            toggleActions: "play",
          },
        }
      );
    });
  }, []);

  return (
    <div className="home-container">
      <NavBar />
      <main>
      <AppIcon icon={logoCCIF} alt='CCIF-logo' className='ccif-img-mobile' />

        <div className="content">

          <div className="text-content">
            <AppTitle
              fontSize='x3-lg'
              title={t('mainTitle')}
            />
            <div>
              <AppTitle title={t('mainDate')}fontSize='md' color='white' isTitle />
              <AppText fontSize="md" text={t('mainSubtitle')} />
            </div>
         
          </div>
          <AppIcon icon={logoCCIF} alt='CCIF-logo' className='ccif-img' />
        </div>
      </main>
      <section className='section-1'>
        {/* <div className='first fixedBanner'>
          <AppText
            text={t('fixedBanner')}
            fontSize='sm'
          />
          <NavLink to='/inscriptions'
                    className='navlink' >
          <AppButton
            text={t('btnRegister')}
            textSize='sm'
            styles={{ backgroundColor: '#F8F8F8' }}
          />
          </NavLink>
        </div> */}
        <div className="second text-anim">
          <AppTitle title={t('firstSectionBlueTitle')} fontSize='x-lg' color='blue' isTitle />
          <AppTitle title={t('firstSectionTitle')} fontSize='x-lg' color='black' isTitle />
        </div>
      </section>
      <section className='section-2 text-anim'>
          <div className="content-container ">
            <div className='first'>
              <AppTitle title={t('secondSectionTitle')} fontSize='x2-lg' color='black' isTitle />
              <AppTitle title={t('secondSectionBlueTitle')} fontSize='x2-lg' color='blue' isTitle />
            </div>
            <div className='second'>
                <AppText
                  text={t('secondSectionSubtitle')}
                  color='grey'
                  fontSize='md'
                />
            </div>
          </div>
          <div className="image-container image-anim">
            <AppIcon icon={locationMap} alt='location map' className="image-anim" />
          </div>
      </section>
      <section className='section-3'>
        <div className='image-container image-anim'>
          <AppIcon icon={filters} alt='filters settings' className='filter-setting' />
          <AppIcon icon={settingIcon} alt='icon setting' className='icon-setting icon-anim' />
        </div>
        <div className="content-container text-anim">
          <div className='first '>
            <AppTitle title={t('thirdSectionTitle')} fontSize='x2-lg' color='black' isTitle />
            <AppTitle title={t('thirdSectionBlueTitle')} fontSize='x2-lg' color='blue' isTitle />
          </div>
          <div className='second'>
            <AppText
              text={t('thirdSectionSubtitle')}
              color='grey'
              fontSize='md'
            />
            <NavLink
              to='download'
            >
            </NavLink>
          </div>
        </div>
      </section>
      <section className='section-3'>
        <div className="content-container text-anim">
          <div className='first'>
            <AppTitle title={t('fourthSectionTitle')} fontSize='x2-lg' color='black' isTitle />
            <AppTitle title={t('fourthSectionBlueTitle')} fontSize='x2-lg' color='blue' isTitle />
          </div>
          <div className='second'>
            <AppText
              text={t('fourthSectionSubtitle')}
              color='grey'
              fontSize='md'
            />
            <NavLink
              to='download'
            >
            </NavLink>
          </div>
        </div>
        <div className='image-container image-anim'>
          <AppIcon icon={enterprise} alt='filters settings' className='filter-setting' />
          <AppIcon icon={iconEconomy} alt='icon setting ' className='icon-setting icon-anim' />
        </div>
      </section>
      <section className='section-3' >
        <div className='image-container image-anim'>
          <AppIcon icon={favoris} alt='filters settings' className='filter-setting' />
          <AppIcon icon={iconPackage} alt='icon setting' className='icon-setting icon-anim icon-setting-left' />
        </div>
        <div className="content-container text-anim">

          <div className='first'>
            <AppTitle title={t('fiveSectionTitle')} fontSize='x2-lg' color='black' isTitle />
            <AppTitle title={t('fiveSectionBlueTitle')} fontSize='x2-lg' color='blue' isTitle />
          </div>
          <div className='second'>
            <AppText
              text={t('fiveSectionSubtitle')}
              color='grey'
              fontSize='md'
            />
            <NavLink
              to='download'
            >
            </NavLink>
          </div>
        </div>
      </section>
      <PracticalInfo />
      {/* <RegisteredCompany /> */}
      <Footer mailto="mailto:public@fribourgopen.ch" mail="public@fribourgopen.ch" />
    </div>
  );
}

export default Home;