import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import App from './App';
import { HashRouter } from 'react-router-dom';
import translationFr from './locales/fr.json';
import translationDe from './locales/de.json';

i18n.init({
  interpolation: { escapeValue: false }, // React already escapes variables
  lng: 'fr', // default language
  resources: {
    fr: {
      translation: translationFr
    },
    de: {
      translation: translationDe
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HashRouter>
    <I18nextProvider i18n={i18n}>
      <App />
      </I18nextProvider>
    </HashRouter>
  </React.StrictMode>
);
