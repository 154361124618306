import React, { useState, useEffect } from 'react';
import Header from './Header';


const headerData = [
    {
        id: 0,
        text: "Dashboard",
    },
    {
        id: 1,
        text: "Profil",
    },
    {
        id: 2,
        text: "Entreprises",
    },
    {
        id: 3,
        text: "Mes favoris",
    },
    {
        id: 4,
        text: "Actualités",
    },
    {
        id: 5,
        text: "Téléchargement",
    },
    {
        id: 6,
        text: "Aide",
    }
]


const Layout = () => {
//   const [title, setTitle] = useState('');

//   useEffect(() => {
//     fetch('APIURL')
//       .then(response => response.json())
//       .then(data => setTitle(data.title));
//   }, []);
  return (
    <div>
    {headerData.map(item => {
        <Header key={item.id} title={item.text} />
    })}
    </div>
  );
};

export default Layout;


