import { NavLink } from "react-router-dom"

import AppButton from "../../Components/Button/AppButton"
import Footer from "../../Components/Footer/Footer"
import AppIcon from "../../Components/Icon/AppIcon"
import NavBar from "../../Components/NavBar/NavBar"
import AppText from "../../Components/Text/AppText"
import AppTitle from "../../Components/Title/AppTitle"
import googlePlay from '../../assets/icons/googleplay.png';


import './download.scss'

const Download = () => {
  return (
    <div className="download-container">
        <NavBar />
        <section className="section-a">
            <div className="first">
                <div className="title">
                    <AppTitle 
                        title='Télécharger FribourgOPEN' fontSize='x2-lg' color='black' isTitle
                    />
                </div>
                <div className="paragraph">
                    <AppText
                        text='It is a long established fact that a reader will be distracted 
                        by the readable content of a page when looking at its layout.'
                        fontSize="md"
                        color='grey'
                    />

                </div>

            </div>

            <div className="second">
                <div className="android-container">
                    <div className="title-container">
                        <AppText 
                            text='Mobile et tablette'
                            color="black"
                        />
                        <AppText
                            text='Android'
                            color='black'
                            isTitle
                            fontSize="x-md"
                        />
                    </div>
                    <div className="information-container">
                        <AppText 
                            text='Minimum Requirements (Version 
                            2.22.13.77) Android OS 4.0.3 or above'
                            color="black"

                        />
                        <AppIcon icon={googlePlay} alt='googlePlay' />
                    </div>
                </div>
                <div className="ios-container">
                    <div className="title-container">
                        <AppText 
                            text='Mobile et tablette'
                            color="white"
                        />
                        <AppText
                            text='iOS'
                            color='white'
                            isTitle
                            fontSize="x-md"
                        />
                    </div>
                    <div className="information-container">
                        <AppText 
                            text='Minimum Requirements (Version 
                                22.13.74) Requires iOS 12.0 or newer'
                            color="white"

                        />
                        <AppIcon icon={googlePlay} alt='googlePlay' />
                    </div>

                </div>

            </div>
            <AppText text='Visitez FribourgOPEN.ch/dl sur votre 
                smartphone pour lancer l’installation !'
                color="black"
                
            />

        </section>
        <Footer />
    </div>  )
}

export default Download