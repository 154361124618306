import { NavLink } from "react-router-dom"

import NavBar from "../../Components/NavBar/NavBar"
import Footer from "../../Components/Footer/Footer"
import AppButton from "../../Components/Button/AppButton"
import AppIcon from "../../Components/Icon/AppIcon"
import AppText from "../../Components/Text/AppText"

import './questions.scss'
import QuestionsSideNav from "../../Components/QuestionSideNav/QuestionsSideNav"
import Question from "../../Components/Question/Question"

const Questions = () => {
    return (
        <div className="questions-container">
            <NavBar />
            <section className="section-question">
                
                <QuestionsSideNav className='first'/>

                <div className="second">
                    <div className="question-content">
                    <AppText 
                        text='Les questions posées fréquemment'
                        color='black'
                        fontSize="x-lg"
                        className='question-title'

                    />
                    <AppText 
                        text='It is a long established fact that a 
                        reader will be distracted by'
                        color='grey'
                        fontSize="md"

                    />
                    <AppText 
                        text='the readable content of a page 
                        when looking at its layout.'
                        color='grey'
                        fontSize="md"

                    />
                    </div>

                    <Question className='question' />

                </div>

            </section>

            <Footer />
        </div>
    )
}

export default Questions


