import './appText.scss';

const AppText = ({text, color = 'white', fontSize = 'sm', opacity, isTitle, className, onClick }) => {
  return (
    <p className={`text
    ${opacity ? 'text-opacity' : ""}
    ${color}-text 
    ${fontSize}-text
    ${isTitle && 'title'}
    ${className}
    `}>
        {text}
    </p>
  )
}

export default AppText