import { NavLink } from "react-router-dom";
import AppButton from '../Button/AppButton';
import AppIcon from '../Icon/AppIcon';
import path from '../../assets/icons/Path.svg';
import burger from '../../assets/icons/burger.png';
import React, { useState } from 'react';
import { ReactComponent as FribourgOpenLogo } from '../../assets/icons/fribourgopenlogo.svg';
import './navbar.scss';
import i18n from 'i18next';
import { useTranslation } from "react-i18next";

const NavBar = () => {
    const { t } = useTranslation();

    const [currentLanguage, setCurrentLanguage] = useState('fr');

    const changeLanguage = () => {
        const newLanguage = currentLanguage === 'fr' ? 'de' : 'fr';
        i18n.changeLanguage(newLanguage);
        setCurrentLanguage(newLanguage);

    };

    const [isBtnsContainerVisible, setIsBtnsContainerVisible] = useState(false);
    const handleBurgerMenuClick = () => {
        setIsBtnsContainerVisible(!isBtnsContainerVisible);
    };

    return (
        <nav>
            <div className='logo-container'>
                <NavLink
                    to='/'
                    className='navlink'
                >
                    <FribourgOpenLogo className='fribourg-logo' />
                </NavLink>
            </div>
            <div className={`btns-container ${isBtnsContainerVisible ? 'visible' : ''}`}>
                <div className='cn-1'>
                    <NavLink
                        to='/'
                        className='navlink'
                    >
                        <AppButton
                            text={t('navVisiteurs')}
                            type='outline'
                            onClick={() => console.log('Visites')}
                            textSize='sm'
                            textOpacity={false}
                            color="grey"
                        />
                    </NavLink>
                    <NavLink
                        to='/inscriptions'
                        className='navlink'
                    >
                        <AppButton
                            text={t('navAbout')}
                            type='outline'
                            onClick={() => console.log('entreprises')}
                            textSize='sm'
                            color="grey"
                            textOpacity={false}
                        />
                    </NavLink>
                    
                </div>
                <div className='cn-2'>
                <a
                        href='https://fribourgopen.ch'
                        className='navlink'
                        rel='noopener noreferrer'
                    >
                        <AppButton
                            text={t('navEntreprises')}
                            type='primary'
                            color="grey"
                            onClick={() => console.log('Aide')}
                            textSize='sm'
                            textOpacity={false}
                        />
                    </a>
                    <AppButton
                        className="btn-translate"
                        text={currentLanguage === "fr" ? "Français" : "Deutsch"}
                        type='tertiary'
                        textColor='black'
                        color="black"
                        styles={{ border: '1px solid #2B2B2B', background: 'none' }}
                        icon={<AppIcon icon={path} alt='path' styles={{ width: '12px', height: '6px' }} className='icon' />}
                        onClick={changeLanguage}
                    />
                </div>
            </div>
            <div className="burger-menu">
                <AppButton
                    type=''
                    styles={{ background: 'none' }}
                    onClick={handleBurgerMenuClick}
                    icon={<AppIcon icon={burger} alt='path' styles={{ width: '31px', height: '22px' }} className='burger-icon' />}
                />
            </div>
        </nav>
    )
}

export default NavBar