import React from 'react';
import AppTitle from '../Title/AppTitle';
import AppText from '../Text/AppText';
import { useTranslation } from 'react-i18next';
import './practicalinfo.scss'

const RegisteredCompany = () => {
    const { t } = useTranslation();
    return (
        <div className='practical-info-container'>
            <section>
                <AppTitle
                    title={t('sixthSectionTitle')} className='title-infos' fontSize='x2-lg' color='black' isTitle
                />
                <div className="pratical-info-content">
                    <div className="infos-question question-1">
                        <AppTitle
                            title={t('whenTitle')} fontSize='md' color='blue' isTitle
                        />
                        <AppText
                            text={t('whenTextOne')}
                            color='black'
                        />
                        <AppText
                            text={t('whenTextTwo')}
                            color='black'
                        />
                        <AppText
                            text={t('whenTextThree')}
                            color='black'
                        />
                    </div>
                    <div className="infos-question question-2">
                        <AppTitle
                            title={t('whereTitle')} fontSize='md' color='blue' isTitle
                        />
                        <AppText
                            text={t('whereText')}
                            color='black'
                        />
                    </div>
                    <div className="infos-question question-3">
                        <AppTitle
                            title={t('whoTitle')} fontSize='md' color='blue' isTitle
                        />
                        <AppText
                            text={t('whoText')}
                            color='black'
                        />
                    </div>
                    <div className="infos-question question-4">
                        <AppTitle
                            title={t('howTitle')} fontSize='md' color='blue' isTitle
                        />
                        <AppText
                            text={t('howText')}
                            color='black'
                        />
                    </div>
                </div>
            </section>
        </div>
    );
}

export default RegisteredCompany;